<template>
  <div class="legal">
    <section class="text-section">
      <div class="title-center title mb-3">
        <h3 class="mb-1">Contacts</h3>
      </div>
      <div class="information">
        <p class="mb-0">Contact details:</p>
        <p>
          You may contact us at any time via our postal address: JUTIFUL LIMITED
          Office 3.05 1 King Street, London, England, EC2V 8AU or via email:
          <a href="mailto:contact@jutiful.com">contact@jutiful.com.</a>
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Contacts",
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/legal.scss";
</style>
