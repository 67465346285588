<template>
  <header>
    <router-link :to="{ name: 'Home' }">
      <img src="@/assets/logo.png" alt="azzl logo" />
    </router-link>
  </header>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
header {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 2.5rem 1rem;
  background-color: white;

  img {
    max-height: 130px;
  }
}
</style>
