<template>
  <div class="home">
    <section class="azzl-section">
      <div class="stores">
        <div class="store">
          <div class="text">
            <h2>ANIMATION PUZZLES</h2>
          </div>
          <a
            class="goto"
            href="https://itunes.apple.com/us/app/azzl/id640484311?ls=1&mt=8"
          >
            <img src="@/assets/stores/app-store.png" />
          </a>
        </div>
      </div>
      <img
        class="azzl-illustation"
        src="@/assets/main/azzl.png"
        alt="azzl illustration"
      />
    </section>
    <section class="about">
      <div class="text">
        <h1>About</h1>
        <div class="sub-text">
          <p>
            Let us introduce our team of juicy specialists and resourceful
            creators called Jutiful since 2012, who develop entertainment and
            game applications.
          </p>
          <p>
            Jutiful specializes in applications and games for iPhone, iPad and
            Android that deliver the maximum number of Yays Per Minute. Being an
            innovative company Jutiful creates an amazing art in the NFT world.
          </p>
        </div>
      </div>
      <div class="pencil">
        <div class="pencil-text">
          <p>Our games</p>
        </div>
        <img src="@/assets/main/pencil.png" alt="azzl pencil" />
      </div>
      <div class="games">
        <a
          href="https://apps.apple.com/us/app/azzl/id640484311?ls=1"
          class="game"
        >
          <div class="image">
            <img src="@/assets/main/azzl-icon.png" alt="azzl logo" />
          </div>
          <h2>AZZL</h2>
        </a>
        <a
          href="https://apps.apple.com/us/app/rebus-absurd-logic-game/id966536279?ls=1"
          class="game"
        >
          <div class="image">
            <img src="@/assets/main/rebus-icon.png" alt="rebus logo" />
          </div>
          <h2>REBUS</h2>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "jutiful",
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  width: 100%;

  section {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .azzl-section {
    position: relative;

    .stores {
      position: absolute;
      bottom: 90px;
      right: 50%;
      transform: translateX(50%);
      display: flex;
      flex-direction: column;

      .store {
        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
          display: flex;
          color: #fff;
          text-align: center;
          margin-bottom: 1.875rem;

          h2 {
            font-size: 1.25rem;
            font-weight: 300;
          }
        }

        .goto {
          display: flex;
          max-width: 192px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .azzl-illustation {
      width: 100%;
      height: 100%;
      min-height: 800px;
      max-height: 950px;
      object-fit: cover;
      background-color: #7af4ff;

      @media (max-width: 600px) {
        min-height: 100vh;
      }
    }
  }

  .about {
    background-color: #667a83;
    align-items: center;
    color: white;

    .text {
      display: flex;
      flex-direction: column;
      max-width: 310px;
      font-weight: 300;
      text-align: center;

      h1 {
        margin-top: 3.75rem;
        margin-bottom: 2.5rem;
        font-size: 2rem;
        font-weight: inherit;
      }

      .sub-text {
        display: flex;
        flex-direction: column;
        line-height: 1.6;

        p:first-child {
          margin-bottom: 1.375rem;
        }
      }
    }

    .pencil {
      position: relative;
      display: flex;
      margin: 2.5rem 2rem 5rem 2rem;
      max-width: 600px;

      .pencil-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        color: #3e474c;
        font-size: 2rem;
        font-weight: 300;

        @media (max-width: 500px) {
          font-size: 1.5rem;
        }

        @media (max-width: 400px) {
          font-size: 1rem;
        }
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .games {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 4.625rem;

      .game {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 170px;
        margin: 0 2.5rem 1rem 2.5rem;
        text-decoration: none;
        color: white;

        &:hover {
          text-decoration: underline;
        }

        h2 {
          font-size: 1.438rem;
          font-weight: 300;
        }

        .image {
          display: flex;
          margin-bottom: 1.875rem;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
