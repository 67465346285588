<template>
  <footer>
    <div class="follow">
      <h2>Follow us</h2>
    </div>
    <div class="socials">
      <a
        class="social"
        href="https://www.facebook.com/pages/Jutiful/1549840751943015"
      >
        <img src="@/assets/socials/facebook.png" />
      </a>
      <a class="social" href="https://twitter.com/Jutiful_Apps">
        <img src="@/assets/socials/twitter.png" />
      </a>
      <a class="social" href="https://instagram.com/jutiful_apps/">
        <img src="@/assets/socials/instagram.png" />
      </a>
      <a
        class="social"
        href="https://www.youtube.com/channel/UCXli8wVKrDWFGL_EHJO0m2Q"
      >
        <img src="@/assets/socials/youtube.png" />
      </a>
      <a class="social" href="https://dribbble.com/jutiful">
        <img src="@/assets/socials/dribbble.png" />
      </a>
    </div>
    <div class="subfooter">
      <div class="subfooter-section">
        <div class="subfooter-text">
          Read our &nbsp;
          <router-link :to="{ name: 'Privacy' }">Privacy policy</router-link>
        </div>
        <div class="subfooter-text">
          Read our &nbsp;
          <router-link :to="{ name: 'Terms' }">Terms of use</router-link>
        </div>
      </div>
      <router-link class="logo" :to="{ name: 'Home' }">
        <img src="@/assets/logo.png" alt="azzl logo" />
      </router-link>
      <div class="subfooter-section">
        <div class="subfooter-text">
          <router-link :to="{ name: 'Contacts' }">Contacts</router-link>
        </div>
        <div class="subfooter-text">
          <p>Jutiful © 2012 - 2022</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 6rem 2rem;
  background-color: #445562;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .follow {
    display: flex;
    color: #929aa7;
    margin-bottom: 1.625rem;

    h2 {
      font-weight: 300;
      font-size: 2rem;
    }
  }

  .socials {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 4.413rem;

    .social {
      display: flex;
      width: 90px;
      height: 90px;
      margin: 0.5rem 1.25rem;
    }
  }

  .subfooter {
    display: flex;
    align-items: center;

    .subfooter-section {
      display: flex;
      flex-direction: column;

      .subfooter-text {
        display: flex;
        font-size: 0.813rem;
        color: #929aa7;

        a {
          color: inherit;
        }
      }
    }

    .logo {
      display: flex;
      max-width: 195px;
      margin: 0 2rem;
    }

    @media (max-width: 600px) {
      flex-direction: column;

      .subfooter-section {
        align-items: center;
      }

      .logo {
        margin: 2rem 0;
      }
    }
  }
}
</style>
