<template>
  <div class="legal">
    <section class="text-section">
      <div class="title-center title mb-3">
        <h3 class="mb-1">JUTIFUL LIMITED</h3>
        <h4>GENERAL TERMS OF USE</h4>
      </div>
      <div class="information">
        <p>
          Whereas, JUTIFUL LIMITED (hereinafter – “JUTIFUL”) is a legal entity
          incorporated and acting under the laws of England and Wales,
        </p>
        <p>
          Whereas, JUTIFUL LIMITED is committed to create high-quality and
          creative mobile apps and NFT products,
        </p>
        <p>
          Whereas, the present General Terms of Use are designated to govern the
          use of the JUTIFUL website by the users and JUTIFUL clients,
        </p>
        <p>
          By accessing or using jutiful.com (hereinafter -JUTIFUL website) the
          User agrees to be bound by the provisions stated in these Terms of
          use, as well as in the other policies accessible on the JUTIFUL
          website, which are hereby incorporated into these Terms of Use and
          expressly agreed and acknowledged by the User and the Client.
        </p>
      </div>
      <div class="title">
        <h3>DEFINITIONS</h3>
      </div>
      <div class="information">
        <p>
          <span class="bold">JUTIFUL LIMITED or JUTIFUL</span> means a legal
          entity incorporated under the Company’s No. 13198487 with the
          registered address at: Office 3.05 1 King Street, London, England,
          EC2V 8AU.
        </p>
        <p>
          <span class="bold">Business day</span> means a day on which banks are
          open and conduct business in England.
        </p>
        <p>
          <span class="bold">Client</span> means the party entering into the
          separate agreement with JUTIFUL.
        </p>
        <p>
          <span class="bold">Services </span> mean services to be agreed and
          provided under the separate agreement executed between JUTIFUL and its
          client.
        </p>
        <p>
          <span class="bold">User</span> means any person which legally uses the
          Website of JUTIFUL.
        </p>
      </div>
      <div class="title">
        <h3>THE LEGAL CAPACITY OF THE USER</h3>
      </div>
      <div class="information">
        <p>
          In order to use JUTIFUL Web site the User has to have a corresponding
          legal capacity required by the applicable law.
        </p>
      </div>
      <div class="title">
        <h3>STATEMENT OF GOOD BUSINESS CONDUCT</h3>
      </div>
      <div class="information">
        <p>
          The User agrees to use the information stated on the JUTIFUL Web site
          only within the designated legal purposes and guarantees the accuracy
          and validity of the data provided to JUTIFUL.
        </p>
      </div>
      <div class="title">
        <h3>NOTIFICATIONS</h3>
      </div>
      <div class="information">
        <p>
          Notifications from JUTIFUL shall be deemed to have been duly served if
          sent to the last known e-mail provided by the User.
        </p>
      </div>
      <div class="title">
        <h3>COMPLIANCE WITH THE LAW</h3>
      </div>
      <div class="information">
        <p>
          The User and the Client shall be responsible for complying with all
          laws and regulations applicable to them. This also includes but not
          limited to declare and pay taxes.
        </p>
        <p>
          These Terms of Use shall be governed by the applicable English law.
          Any dispute arising out of JUTIFUL’s website is subject to the
          jurisdiction of the English Courts.
        </p>
      </div>
      <div class="title">
        <h3>OUTSOURCING OF OPERATIONS AND SERVICES</h3>
      </div>
      <div class="information">
        <p>
          JUTIFUL may outsource operations and services to its subsidiaries or
          third parties within Great Britain, EU and abroad. Outsourcing may
          require the transfer of data to affiliated or third party services
          providers. All service providers are required to comply with English
          legislation on personal data treatment and confidentiality
          obligations.
        </p>
      </div>
      <div class="title">
        <h3>DATA PROTECTION</h3>
      </div>
      <div class="information">
        <p>
          JUTIFUL shall not disclose non-public personal information about its
          User or Clients, unless it may be necessary to disclose including but
          not limited in the following cases:
        </p>
        <ul class="list-dash-style">
          <li>
            where it is necessary for the good and proper administration or
            conduct of the services or request agreed or submitted by the User
            or the Client;
          </li>
          <li>
            where such sharing is reasonably necessary for the proper conduct of
            JUTIFUL business activity;
          </li>
          <li>
            to protect against, or prevent, actual or potential fraud, identity
            theft, as well as for resolving User’s or Client’s disputes or
            inquiries;
          </li>
          <li>
            to comply with local laws or regulation, other legal requirements or
            other legal processes.
          </li>
        </ul>
        <p>
          By agreeing to these Terms of Use, the User hereby agrees to the
          collection, storage, and processing of its personal information and
          that such data being used and transmitted to others, as set out in
          these Terms of Use.
        </p>
        <p>
          Unless the User has indicated otherwise, JUTIFUL may contact the User
          (by telephone, mail, or other means) with information about the
          products and services available which JUTIFUL believes may be of
          interest to the User. In case if the User does not agree to receive
          from JUTIFUL marketing promotions, the User has to notify JUTIFUL in
          writing on it.
        </p>
        <p>
          Notwithstanding the fact that JUTIFUL LIMITED deploys reasonable
          security measures to protect against the loss and misuse of the
          personal data transmitted to it by the Users, JUTIFUL shall not be
          liable for any harm or damage suffered by the User by sending personal
          or confidential information to us over the internet or by email and
          JUTIFUL shall not be liable for any act or negligence committed by any
          third person in relation to the information transmitted by email.
        </p>
        <p>
          All further details with regard to these provisions are specified in
          the Privacy Policy.
        </p>
      </div>
      <div class="title">
        <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
      </div>
      <div class="information">
        <p>
          The content of the JUTIFUL website and all intellectual property
          pertaining thereto and contained therein are owned by JUTIFUL LIMITED
          or licensed through third parties, and all rights, title, and interest
          therein and thereto shall remain the property of JUTIFUL LIMITED or of
          such third parties.
        </p>
        <p>
          Subject to your compliance with this Terms of Use, JUTIFUL grants you
          a personal, non-commercial, non-transferable, revocable, limited
          license to use and display all content available on the JUTIFUL
          website.
        </p>
      </div>
      <div class="title">
        <h3>MODIFICATIONS OF THE TERMS OF USE AND OTHER JUTIFUL POLICIES</h3>
      </div>
      <div class="information">
        <p>
          JUTIFUL reserves the right at the sole discretion to change or modify
          these Terms of Use, as well as the other policies publicly available
          on JUTIFUL website at any time. Such modifications are effective
          immediately from the moment they become accessible on the JUTIFUL
          website. It is an obligation of the Use or the Client to review these
          Terms of Use on the regular basis to be aware of them.
        </p>
      </div>
      <div class="title">
        <h3>MODIFICATIONS OF THE TERMS OF USE AND OTHER JUTIFUL POLICIES</h3>
      </div>
      <div class="information">
        <p>
          JUTIFUL provides zero-tolerance to any form of the bribery or
          corruption and conducts its business activity in full compliance with
          the Bribery act 2010.
        </p>
      </div>
      <div class="title">
        <h3>REPRESENTATIONS AND WARRANTIES</h3>
      </div>
      <div class="information">
        <p>
          The JUTIFUL website is provided "as is" and without warranty or
          condition. We expect that the User in each case acts in accordance
          with his respective powers and duties.
        </p>
        <p>
          The User and the Client undertake to fully comply with these terms of
          Use.
        </p>
        <p>
          These terms will not limit any non-waivable warranties or consumer
          protection rights that the User and the Client may be entitled to
          under the mandatory laws of your country of residence.
        </p>
      </div>
      <div class="title">
        <h3>MISCELLANEOUS</h3>
      </div>
      <div class="information">
        <p>
          JUTIFUL shall be fully entitled to put, moderate or remove any
          material from its website any time. In case if the User considers that
          any material is offensive he is welcome to contact JUTIFUL immediately
          <a href="mailto:contact@jutiful.com">contact@jutiful.com.</a>
        </p>
        <p>
          The User consents and JUTIFUL reserves the right within the purpose of
          its business activity and within the applicable law to record and
          store all telephone conversations, electronic and other written
          communications. Such records may be used as evidence for any legal
          purpose.
        </p>
        <p>
          In case of any provision of these Terms of Use shall be held to be
          unenforceable by a decision of the competent court, all other
          provisions of the Terms of Use shall stay in force.
        </p>
        <p>
          The information provided on the JUTIFUL website is provided only for
          general information purposes or for the purpose of establishment of
          the potential business relationship between JUTIFUL and its User.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Terms of use",
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/legal.scss";
</style>
