import { createRouter, createWebHistory } from "vue-router";
import Home from "../pages/Home.vue";
import Privacy from "../pages/privacy/Privacy.vue";
import Terms from "../pages/terms/Terms.vue";
import Contacts from "../pages/contacts/Contacts.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "jutiful",
    },
  },
  {
    path: "/privacypolicy",
    name: "Privacy",
    meta: {
      title: "Privacy policy",
    },
    component: Privacy,
  },
  {
    path: "/terms",
    name: "Terms",
    meta: {
      title: "Terms of use",
    },
    component: Terms,
  },
  {
    path: "/contacts",
    name: "Contacts",
    meta: {
      title: "Contacts",
    },
    component: Contacts,
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: Home },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    if (to.name === from.name) return;
    window.scrollTo(0, 0);
  },
});

router.beforeEach((to) => {
  document.title = to.meta.title || "jutiful";
});

export default router;
