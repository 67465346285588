<template>
  <div class="wrapper">
    <app-header />
    <router-view />
    <app-footer />
  </div>
</template>

<script>
import AppHeader from "./components/app-header.vue";
import AppFooter from "./components/app-footer.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>

<style lang="scss">
*,
::after,
::before {
  box-sizing: border-box;
}

blockquote,
body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
html,
li,
ol,
p,
ul {
  margin: 0;
  padding: 0;
}

body,
html {
  font-family: "Roboto", sans-serif;
  color: #202020;
  font-size: 100%;
  height: 100%;
  line-height: initial;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}

#app {
  height: 100%;
  width: 100%;
}
</style>
