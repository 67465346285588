<template>
  <div class="legal">
    <section class="text-section">
      <div class="title-center title mb-3">
        <h3 class="mb-1">JUTIFUL PRIVACY POLICY</h3>
      </div>
      <div class="information">
        <p>
          <span class="h3">JUTIFUL LIMITED</span>&nbsp;
          <span class="bold">(we)</span> are committed to protecting your
          personal data and respecting your privacy.
        </p>
      </div>
      <div class="title">
        <h3>INTRODUCTION</h3>
      </div>
      <div class="information">
        <p>
          This Policy applies to the information we may collect through your use
          of the jutiful.com (the Website) and through e-mail communications
          between you and JUTIFUL LIMITED.
        </p>
        <p>
          This Policy shall come into effect at the moment you first access the
          Website or start an e-mail communication and sets out the basis on
          which any personal data we collect from you, or provided by you, if it
          takes place, will be processed by us. By accessing, browsing or using
          the Website and/or starting an e-mail communication, you irrevocably
          and unconditionally accept and adhere to the provisions of this Policy
          without any exemptions, limitations and/or exclusions. Please read the
          following carefully to understand our practices regarding your
          personal data and how we will treat it.
        </p>
        <p>
          If you access the Website or start an e-mail communication on behalf
          of a business (whether registered or operating without registration),
          that business hereby accepts this Policy.
        </p>
        <p>
          This Policy does not apply to your use of our or our affiliates’
          websites, where the relevant Privacy Policy is applicable.
        </p>
        <p>
          In case of disagreement with any of the Privacy Policy provision or
          failure to provide a consent for personal data processing, your using
          of the Website should be terminated as promptly as practicable.
        </p>
      </div>
      <div class="title">
        <h3>IMPORTANT INFORMATION AND WHO WE ARE</h3>
      </div>
      <div class="information">
        <p>
          JUTIFUL LIMITED is the controller and is responsible for the
          collection, processing, and use of your personal data through the
          Website usage and communication via e-mail (collectively referred to
          as “Company”, “we”, “us” or “our” in this policy).
        </p>
        <h4>Contact details</h4>
        <ul>
          <li>Full name of legal entity: Jutiful Limited</li>
          <li>
            Email address:
            <a href="mailto:contact@jutiful.com">contact@jutiful.com.</a>
          </li>
          <li>
            Postal address: Office 3.05 1 King Street, London, England, EC2V 8AU
          </li>
        </ul>
        <p>
          For any questions about privacy in connection with the Website usage,
          you can always contact us by submitting a request using the
          above-mentioned contact information. If you submit a support request,
          we may receive your email address and other information you provide
          related to your support request (“Support Information”). We use
          Support Information only.
        </p>
        <h4>
          Changes to the privacy policy and your duty to inform us of changes
        </h4>
        <p>We keep our privacy policy under regular review.</p>
        <p>
          JUTIFUL LIMITED reserves the right, at the sole discretion, to change
          or modify these Privacy Policy at any time. If we do this, we will
          post the changes on this page and will indicate at the top of this
          page the date these terms were last revised.
        </p>
        <p>
          This Privacy Policy is effective as of the date on which it is made
          available on the site and is effective as of 13<sup>th</sup> September
          2021.
        </p>
        <h4>Contact details</h4>
        <p>
          The Website doesn’t contain any third-party advertising banners, but
          they may contain information about our other applications, features,
          components and/or services created, developed and distributed by
          JUTIFUL LIMITED (hereinafter – “JUTIFUL Products”) with links to such
          products (either to Google Play, App Store or Amazon).
        </p>
        <p>
          The Website may contain external links to our pages on the App Store,
          Google Play, Facebook, Instagram, YouTube channel, Twitter or Amazon
        </p>
        <p>
          This Policy does not extend to the use of tracking technologies by
          third parties. The Website may contain links, content or advertising,
          to other websites by third parties, including advertisers, ad networks
          and servers, content providers, and application providers, that could
          possibly use cookies or other tracking technologies to collect
          information about you while using the services. The information
          collected by third parties may be associated with your personal
          information or they may collect information about your online
          activities over time and across different websites. Please be kindly
          informed that we do not control these third parties' tracking
          technologies or when and how they may be used. Therefore, JUTIFUL
          LIMITED does not claim nor accept responsibility for any privacy
          policies, practices, or procedures of any third party. We encourage
          you to read the privacy statements and terms and conditions of linked
          or referenced websites you enter. If you have any questions about an
          advertising or other targeted content, you should contact the
          responsible provider as promptly as practicable.
        </p>
      </div>
      <div class="title">
        <h3>THE DATA WE COLLECT ABOUT YOU</h3>
      </div>
      <div class="information">
        <p>
          We understand the importance of our users’ personal data, and more
          importantly the information pertaining to children under 18 years of
          age (for the purpose of the U.K. DPA).
        </p>
        <p class="bold">
          We do not collect or require users to enter their personal information
          when they use the Website.
        </p>
        <p>
          While communicating with JUTIFUL LIMITED via e-mail, the information
          given by you is received, which is described in further details below.
        </p>
        <p>(a) Information given by you:</p>
        <p class="mb-0">
          We collect personal information that you knowingly choose to disclose.
          This may include:
        </p>
        <ul class="list-number-style">
          <li>
            <span class="text-underline">Personal Information.</span> Personal
            information, such as your name, e-mail address, and any other
            information you directly provide us in the e-mail.
          </li>
          <li>
            <span class="text-underline">Email Correspondences.</span> Records
            and copies of your email messages together with your email address
            and our responses, if you communicate with us through email.
          </li>
        </ul>
        <h4>We do not collect any other information</h4>
        <p class="mb-0">
          When you use The Website we don’t require you to provide any
          information and data about you or your device, except for nonpersonal
          information that necessary for initialization and work of The Website.
        </p>
        <p>
          We may offer for you any paid services or features within The Website
          and Jutiful Products. In all such cases, if they are available, you
          make payments for our offers via services and features provided and
          supported by Apple and Google. They directly process and collect your
          payment data according to your agreement with them with respect to
          your usage of their respective services and features. We do not
          collect any your payment data and do not authorize or instruct Apple
          or Google to collect and store such data on our behalf or in our
          favor.
        </p>
        <p>
          In some cases, our Apps provide you with ability to indicate some
          personal data of your child like name or full name and age or make a
          photo. In each such cases we do not collect and store such data. This
          information is recorded on your devices only, where Apps have been
          installed. This data will be irrevocably deleted upon uninstallation
          or deleting our Apps from your device.
        </p>
        <p>
          We don’t collect any other personal data, including automatically
          collected information except as explained above. We do not share such
          information with third parties.
        </p>
        <p>
          We do not collect any Special Categories of Personal Data about you
          (this includes details about your race or ethnicity, religious or
          philosophical beliefs, sex life, sexual orientation, political
          opinions, trade union membership, information about your health, and
          genetic and biometric data). Nor do we collect any information about
          criminal convictions and offences.
        </p>
        <p>
          Also, JUTIFUL LIMITED doesn’t use or collect a user’s precise
          geographic, statistical, or other aggregate information location.
        </p>
      </div>
      <div class="title">
        <h3>HOW IS YOUR PERSONAL DATA COLLECTED?</h3>
      </div>
      <div class="information">
        <p>
          The information is collected through e-mail communication with you.
        </p>
        <p>
          We do not collect any other information, except your e-mail and name.
        </p>
        <p>
          We don’t collect any other personal data, except as explained above.
          We do not share such information with third parties.
        </p>
        <p>
          We do not collect any Special Categories of Personal Data about you
          (this includes details about your race or ethnicity, religious or
          philosophical beliefs, sex life, sexual orientation, political
          opinions, trade union membership, information about your health, and
          genetic and biometric data). Nor do we collect any information about
          criminal convictions and offences.
        </p>
      </div>
      <div class="title">
        <h3>HOW WE USE YOUR PERSONAL DATA</h3>
      </div>
      <div class="information">
        <p>
          We will only use your personal data, if we collect it as describe
          above, when the law allows us to do so. Most commonly we will use your
          personal data in the following circumstances:
        </p>
        <p>
          (a) Where it is necessary for our legitimate interests (or those of a
          third party) and your interests and fundamental rights do not override
          those interests.
        </p>
        <p>
          Legitimate interests means the interest of our business in conducting
          and managing our business to enable us to give you the best version of
          every Jutiful product and the best and most secure experience. We make
          sure we consider and balance any potential impact on you (both
          positive and negative) and your rights before we process your personal
          data for our legitimate interests. We do not use your personal data
          for activities where our interests are overridden by the impact on you
          (unless we have your consent or are otherwise required or permitted to
          by law). You can obtain further information about how we assess our
          legitimate interests against any potential impact on you in respect of
          specific activities by contacting us.
        </p>
        <p>
          (b) Where we need to comply with a legal or regulatory obligation.
        </p>
        <p>
          We may process a user’s data if it is required by the law that applies
          to us. For example, we may provide data on users at the request of
          courts or other authorized bodies or when collection and
          identification are required by the legislation on financial monitoring
          and prevention of money laundering or by other applicable laws.
        </p>
        <p>(c) Where you have consented before the processing.</p>
        <p>
          We rely solely upon the user's consent when using personal user data
          for the purpose of displaying targeted advertising, marketing and
          promotional mailing, or for other purposes for which a specific user’s
          consent must be granted.
        </p>
      </div>
      <div class="title">
        <h3>HOW WE PROTECT YOUR INFORMATION</h3>
      </div>
      <div class="information">
        <p>
          We take appropriate technical and organizational security measures to
          protect your data against any accidental or intentional manipulation,
          partial or total loss, destruction or against unauthorized access by
          third parties. Our security measures are continuously improved in line
          with technological developments.
        </p>
        <p>
          Although we take precautions intended to help protect the personal
          information that we collect and process, no system or electronic data
          transmission is completely secure. Any transmission of your personal
          information is at your own risk and we expect that you will use
          appropriate security measures to protect your personal information.
        </p>
        <p>
          We may suspend your use of the Website without notice if we suspect or
          detect any breach of security. You understand and agree that we may
          deliver electronic notifications about breaches of security to the
          email address that you provided to us.
        </p>
      </div>
      <div class="title">
        <h3>LEGAL RIGHTS AND WITHDRAWAL</h3>
      </div>
      <div class="information">
        <p>This Policy is based on several data protection laws, including:</p>
        <ul>
          <li>
            the Privacy and Electronic Communications (EC Directive) Regulations
            2003
          </li>
          <li>the Data Protection Act 2018</li>
          <li>General Data Protection Regulation (EU) 2016/679</li>
        </ul>
        <p>
          According to the above-mentioned data protection laws, you have the
          following rights:
        </p>
        <p>
          <span class="bold">Request access</span> to your personal data
          (commonly known as a “data subject access request”). This enables you
          to receive a copy of the personal data we hold about you and to check
          that we are lawfully processing it.
        </p>
        <p>
          <span class="bold">Request correction</span> of the personal data that
          we hold about you. This enables you to have any incomplete or
          inaccurate data we hold about you corrected, though we may need to
          verify the accuracy of the new data you provide to us.
        </p>
        <p>
          <span class="bold">Request erasure</span> of your personal data. This
          enables you to ask us to delete or remove personal data where there is
          no good reason for us continuing to process it. You also have the
          right to ask us to delete or remove your personal data where you have
          successfully exercised your right to object to processing (see below),
          where we may have processed your information unlawfully or where we
          are required to erase your personal data to comply with local law.
          Note, however, that we may not always be able to comply with your
          request of erasure for specific legal reasons which will be notified
          to you, if applicable, at the time of your request.
        </p>
        <p>
          <span class="bold">Object to processing</span> of your personal data
          where we are relying on a legitimate interest (or those of a third
          party) and there is something about your particular situation which
          makes you want to object to processing on this ground as you feel it
          impacts on your fundamental rights and freedoms. You also have the
          right to object where we are processing your personal data for direct
          marketing purposes. In some cases, we may demonstrate that we have
          compelling legitimate grounds to process your information which
          override your rights and freedoms.
        </p>
        <p>
          <span class="bold">Request restriction</span> of processing of your
          personal data. This enables you to ask us to suspend the processing of
          your personal data in the following scenarios:
        </p>
        <p>(a) if you want us to establish the data’s accuracy;</p>
        <p>
          (b) where our use of the data is unlawful but you do not want us to
          erase it;
        </p>
        <p>
          (c) where you need us to hold the data even if we no longer require it
          as you need it to establish, exercise or defend legal claims; or
        </p>
        <p>
          (d) you have objected to our use of your data but we need to verify
          whether we have overriding legitimate grounds to use it.
        </p>
        <p>
          <span class="bold">Request the transfer</span> of your personal data
          to you or to a third party. We will provide to you, or a third party
          you have chosen, your personal data in a structured, commonly used,
          machine-readable format. Note that this right only applies to
          automated information which you initially provided consent for us to
          use or where we used the information to perform a contract with you.
        </p>
        <p>
          <span class="bold">Withdraw consent</span> at any time where we are
          relying on consent to process your personal data. However, this will
          not affect the lawfulness of any processing carried out before you
          withdraw your consent. If you withdraw your consent, we may not be
          able to provide certain products or services to you. We will advise
          you if this is the case at the time you withdraw your consent.
        </p>
        <p>
          You also have the right to ask us not to continue to process your
          personal data for marketing purposes.
        </p>
        <p>
          If you would like to exercise any of these rights at any time, please
          contact us at
          <a href="mailto:contact@jutiful.com">contact@jutiful.com.</a>
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Privacy policy",
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/legal.scss";
</style>
